.Slider {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .Slider_row {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding-top: 0.2em;
    font-weight: 300;
    font-size: 0.9em; }
  .Slider .rc-slider {
    width: 100%; }
  .Slider .rc-slider-track {
    background: linear-gradient(180deg, #BC9CFF 0%, #8BA4F9 100%);
    height: 6px; }
  .Slider .rc-slider-rail {
    background-color: #f1f1f1;
    border: 1px solid rgba(152, 152, 152, 0.25);
    border-radius: 3px;
    height: 6px; }
  .Slider .rc-slider-handle {
    background: linear-gradient(180deg, #BC9CFF 0%, #8BA4F9 100%);
    border: 2px solid #FFFFFF;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    margin-top: -8px; }
