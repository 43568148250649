.Offer {
  background-color: #2196f3;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 70vh; }
  @media screen and (max-width: 991px) {
    .Offer {
      height: 100%;
      min-height: 630px; } }
  .Offer_wrapper {
    width: 100%;
    max-width: 1400px;
    background-image: url(/castweek.png);
    background-size: 700px;
    background-position: right bottom -130px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    padding: 5em 2em 2em; }
    @media screen and (max-width: 1280px) {
      .Offer_wrapper {
        background-size: 580px; } }
    @media screen and (max-width: 1120px) {
      .Offer_wrapper {
        padding: 100px 25px 10px 25px;
        background-size: 580px; } }
    @media screen and (max-width: 991px) {
      .Offer_wrapper {
        padding: 60px 25px 10px 25px;
        align-items: flex-start;
        justify-content: center;
        background-size: 430px;
        background-position: center bottom; } }
    @media screen and (max-width: 500px) {
      .Offer_wrapper {
        padding: 100px 10px 10px 10px;
        background-size: 450px; } }
    @media screen and (max-width: 400px) {
      .Offer_wrapper {
        padding: 80px 10px 10px 10px;
        background-size: 390px; } }
    @media screen and (max-width: 320px) {
      .Offer_wrapper {
        padding: 80px 10px 0 10px;
        background-size: 350px; } }
    .Offer_wrapper:after {
      content: "";
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 70vh; }
      @media screen and (max-width: 991px) {
        .Offer_wrapper:after {
          height: 100vh; } }
  .Offer_inner {
    max-width: 630px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left; }
    @media screen and (max-width: 1280px) {
      .Offer_inner {
        width: 550px; } }
    @media screen and (max-width: 1120px) {
      .Offer_inner {
        width: 100%;
        width: 470px; } }
  .Offer h1 {
    margin-bottom: 20px;
    font-size: 5.5rem;
    line-height: 1.2; }
    @media screen and (max-width: 1280px) {
      .Offer h1 {
        font-size: 5rem; } }
    @media screen and (max-width: 1120px) {
      .Offer h1 {
        font-size: 4.5rem; } }
    @media screen and (max-width: 600px) {
      .Offer h1 {
        font-size: 4rem; } }
    @media screen and (max-width: 480px) {
      .Offer h1 {
        font-size: 3.4rem;
        margin-top: 0;
        margin-bottom: 10px; } }
    @media screen and (max-width: 320px) {
      .Offer h1 {
        font-size: 2.7rem; } }
  .Offer h2 {
    font-size: 3rem;
    margin-bottom: 25px;
    font-weight: 500;
    max-width: 600px;
    line-height: 1.3; }
    @media screen and (max-width: 1280px) {
      .Offer h2 {
        font-size: 2.8rem; } }
    @media screen and (max-width: 1120px) {
      .Offer h2 {
        font-size: 2.3rem; } }
    @media screen and (max-width: 776px) {
      .Offer h2 {
        font-size: 2.8rem;
        max-width: 400px; } }
    @media screen and (max-width: 480px) {
      .Offer h2 {
        font-size: 2.5rem;
        margin-bottom: 18px;
        max-width: 400px; } }
    @media screen and (max-width: 320px) {
      .Offer h2 {
        font-size: 2rem;
        margin-top: 5px; } }
  .Offer .container {
    width: 1em;
    height: 5em;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }
  .Offer .chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    -webkit-animation: move 3s ease-out infinite;
            animation: move 3s ease-out infinite; }
  .Offer .chevron:first-child {
    -webkit-animation: move 3s ease-out 1s infinite;
            animation: move 3s ease-out 1s infinite; }
  .Offer .chevron:nth-child(2) {
    -webkit-animation: move 3s ease-out 2s infinite;
            animation: move 3s ease-out 2s infinite; }
  .Offer .chevron:before,
  .Offer .chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff; }
  .Offer .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg); }
  .Offer .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg); }

@-webkit-keyframes move {
  25% {
    opacity: 1; }
  33% {
    opacity: 1;
    transform: translateY(30px); }
  67% {
    opacity: 1;
    transform: translateY(40px); }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5); } }

@keyframes move {
  25% {
    opacity: 1; }
  33% {
    opacity: 1;
    transform: translateY(30px); }
  67% {
    opacity: 1;
    transform: translateY(40px); }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5); } }
  .Offer .text {
    display: block;
    margin-top: 5.8em;
    margin-left: -2.3em;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: .25;
    -webkit-animation: pulse 2s linear alternate infinite;
            animation: pulse 2s linear alternate infinite; }

@-webkit-keyframes pulse {
  to {
    opacity: 1; } }

@keyframes pulse {
  to {
    opacity: 1; } }
