.FooterPage {
  bottom: 0;
  width: 100%;
  color: black;
  margin-top: 3em; }
  .FooterPage li.social_link {
    display: flex;
    flex-direction: row; }
  .FooterPage li.social_link a svg {
    width: 45px;
    display: block;
    height: 45px;
    transition: .3s; }
  .FooterPage li.social_link a {
    margin-bottom: 0; }
  .FooterPage li.social_link a svg path {
    transition: .3s; }
  .FooterPage li.social_link a svg:hover path {
    fill: #2196f3;
    transition: .3s; }
  .FooterPage .FooterPage_col.contacts a {
    font-size: 20px;
    margin-bottom: 5px;
    display: block; }
  .FooterPage > div {
    padding: 1em; }
  .FooterPage a {
    color: #333;
    text-decoration: none; }
  .FooterPage_logo {
    max-width: 12em; }
    .FooterPage_logo img {
      max-width: 70%; }
  .FooterPage_row-wrapper {
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    font-size: 1.5rem; }
    @media screen and (max-width: 776px) {
      .FooterPage_row-wrapper {
        flex-direction: column; } }
  .FooterPage_row_2 {
    font-size: 0.8em;
    color: black;
    margin-top: 1em; }
  .FooterPage_col {
    flex-direction: column; }
    @media screen and (max-width: 1120px) {
      .FooterPage_col {
        margin-top: 2em; } }
  .FooterPage_title {
    margin-bottom: 1.25rem;
    font-size: 2.1em;
    font-weight: bold; }
  .FooterPage ul li {
    list-style-type: none;
    padding: 0.2em 0;
    font-size: 1.4em; }
