.ServiceCard {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  height: 20rem;
  width: 20rem;
  background-color: #2196f3;
  background-position: center;
  background-clip: border-box;
  background-origin: border-box;
  background-size: cover;
  position: relative;
  background: #2196f3; }
  @media screen and (max-width: 500px) {
    .ServiceCard {
      width: 95%;
      margin: 0 0 1rem 0; } }
  .ServiceCard_bg_gradient-purlpe {
    background: #2196f3; }
  .ServiceCard:before {
    content: ' ';
    background: #2196f3;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    border-radius: 10px;
    transition: all 0.3s ease; }
  .ServiceCard__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    border: 3px solid white;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.2);
    transition: all 0.3s ease;
    overflow: hidden; }
  .ServiceCard:hover .ServiceCard__content {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    border-radius: 3px; }
  .ServiceCard:hover:before {
    opacity: 0.9; }
  .ServiceCard__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 2rem - 0.3rem);
    height: 4rem;
    margin: 0.6rem 1rem;
    font-size: 1.6rem;
    font-weight: 200;
    color: white;
    text-align: center; }
  .ServiceCard:hover .ServiceCard__header {
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden; }
  .ServiceCard__services-list {
    position: relative;
    list-style-position: inside;
    list-style-type: none;
    line-height: 1.2rem;
    margin: 1rem 1rem; }
  .ServiceCard__list-item {
    position: relative;
    font-size: 1.2rem;
    color: white; }
    .ServiceCard__list-item:not(:last-child) {
      margin-bottom: 0.9rem; }
    .ServiceCard__list-item a {
      color: white;
      text-decoration: none; }
      .ServiceCard__list-item a:hover {
        color: white;
        text-decoration: none; }
      .ServiceCard__list-item a:visited {
        color: white;
        text-decoration: none; }
