.SliderWrapper {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .SliderWrapper_row {
    display: flex;
    align-items: start; }
  .SliderWrapper_nav {
    display: flex;
    justify-content: space-between;
    max-width: 440px;
    width: 100%;
    font-size: 4rem;
    margin: 1.5em 0;
    padding: 0 20px; }
    .SliderWrapper_nav div {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .SliderWrapper_nav div svg {
        width: 30px;
        height: 27px;
        transition: .3s; }
        .SliderWrapper_nav div svg:hover path {
          fill: #2196f3; }

.SliderWrapper_nav_marks {
  display: flex;
  justify-content: center; }

.slider-page-mark {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: gainsboro; }
  .slider-page-mark-active {
    background-color: #2196f3; }
  .slider-page-mark:not(:last-child) {
    margin-right: 0.6rem; }
