.CasesShort {
  margin-top: 200px;
  overflow: hidden; }
  @media screen and (max-width: 400px) {
    .CasesShort {
      margin-top: 150px; } }
  .CasesShort .Section_title {
    width: 100%; }

.CasesWrapper {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .CasesWrapper_row {
    display: flex;
    align-items: start; }
  .CasesWrapper_nav {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    font-size: 4rem;
    margin: 1.5em 0; }
    .CasesWrapper_nav div {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .CasesWrapper_nav div svg {
        width: 30px;
        height: 27px;
        transition: .3s; }
        .CasesWrapper_nav div svg:hover path {
          fill: #2196f3; }

.CasesWrapper_nav_marks {
  display: flex;
  justify-content: center; }

.slider-page-mark {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: gainsboro; }
  .slider-page-mark-active {
    background-color: #2196f3; }
  .slider-page-mark:not(:last-child) {
    margin-right: 0.6rem; }

.story-box {
  max-width: 42em;
  margin: 0 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(55, 84, 170, 0.1); }
  @media screen and (max-width: 776px) {
    .story-box {
      max-width: 310px; } }
  .story-box .story-inner {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px; }
    @media screen and (max-width: 776px) {
      .story-box .story-inner {
        flex-direction: column; } }
    .story-box .story-inner .story-img {
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat; }
      .story-box .story-inner .story-img img {
        width: 100%;
        border-radius: 10px 10px 0 0;
        height: auto; }
    .story-box .story-inner .story-content {
      width: 400px;
      padding: 20px;
      position: relative; }
      @media screen and (max-width: 1120px) {
        .story-box .story-inner .story-content {
          width: 320px; } }
      @media screen and (max-width: 776px) {
        .story-box .story-inner .story-content {
          margin-left: 0;
          margin-top: 0; } }
      .story-box .story-inner .story-content .story-logo img {
        width: auto;
        max-height: 30px; }
      .story-box .story-inner .story-content h4 {
        margin-bottom: 10px;
        font-size: 2.4rem; }
        @media screen and (max-width: 776px) {
          .story-box .story-inner .story-content h4 {
            margin-bottom: 10px;
            font-size: 2rem; } }
      .story-box .story-inner .story-content .story-description .fa-check {
        color: #5a5a5a;
        font-size: 13px;
        margin-right: 5px; }
      .story-box .story-inner .story-content .story-description p {
        font-size: 2rem;
        margin-bottom: 10px; }
        @media screen and (max-width: 776px) {
          .story-box .story-inner .story-content .story-description p {
            font-size: 2rem; } }
      .story-box .story-inner .story-content .story-logo {
        margin-bottom: 20px;
        position: absolute;
        left: 10px;
        top: -30px;
        background: white;
        padding: 6px 10px;
        border-radius: 9px;
        box-shadow: 0px -10px 10px rgba(55, 84, 170, 0.1); }
        @media screen and (max-width: 776px) {
          .story-box .story-inner .story-content .story-logo {
            margin-bottom: 10px; } }
