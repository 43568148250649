.PerformersFull {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2em 0 0 0; }
  .PerformersFull_item {
    width: 30%;
    transition: 0.3s;
    margin: 1%;
    background: rgba(0, 0, 0, 0.5); }
