.ContentPage {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ContentPage > * {
    width: 100%; }

.Block_UserQuestionsWizard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 2em;
  position: relative;
  background: #2196f3;
  margin: 120px 0;
  min-height: 35em; }
  @media screen and (max-width: 991px) {
    .Block_UserQuestionsWizard {
      padding: 6rem 1em; } }
  .Block_UserQuestionsWizard:after, .Block_UserQuestionsWizard:before {
    content: "";
    position: absolute;
    left: 0;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 60px;
    z-index: -1; }
  .Block_UserQuestionsWizard:after {
    top: -58px;
    background: url(/svg/CTA_bg-top.svg) no-repeat; }
  .Block_UserQuestionsWizard:before {
    bottom: -58px;
    background: url(/svg/CTA_bg-bottom.svg) no-repeat; }

.Block_UserQuestionsWizard_Inner {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap; }
  .Block_UserQuestionsWizard_Inner .QuestionsWizard_CTA {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media screen and (max-width: 1120px) {
      .Block_UserQuestionsWizard_Inner .QuestionsWizard_CTA {
        max-width: 100%;
        align-items: center;
        text-align: center;
        margin-bottom: 3em; } }
    .Block_UserQuestionsWizard_Inner .QuestionsWizard_CTA h3 {
      font-size: 5rem;
      color: white;
      line-height: 1.2;
      margin-bottom: 10px; }
      @media screen and (max-width: 480px) {
        .Block_UserQuestionsWizard_Inner .QuestionsWizard_CTA h3 {
          font-size: 4rem; } }
    .Block_UserQuestionsWizard_Inner .QuestionsWizard_CTA p {
      font-size: 2.5rem;
      color: white;
      line-height: 1.5; }
      @media screen and (max-width: 480px) {
        .Block_UserQuestionsWizard_Inner .QuestionsWizard_CTA p {
          font-size: 2rem; } }
