.StatBlock {
  max-width: 1120px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 70px;
  flex-wrap: wrap; }
  @media screen and (max-width: 776px) {
    .StatBlock {
      flex-direction: column;
      align-items: center; } }
  .StatBlock .StatBlock_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 33%;
    line-height: 1;
    min-width: 240px;
    text-align: center; }
    @media screen and (max-width: 776px) {
      .StatBlock .StatBlock_block {
        margin-bottom: 40px;
        width: 100%; }
        .StatBlock .StatBlock_block:last-child {
          margin-bottom: 0; } }
    @media screen and (max-width: 480px) {
      .StatBlock .StatBlock_block {
        margin-bottom: 35px; } }
    .StatBlock .StatBlock_block-accent {
      color: #fb8c0a; }
  .StatBlock .StatBlock_number {
    font-size: 65px;
    font-weight: 600; }
  .StatBlock .StatBlock_title {
    font-size: 22px;
    margin-top: 10px; }
    @media screen and (max-width: 480px) {
      .StatBlock .StatBlock_title {
        margin-top: 0; } }
