@charset "UTF-8";
.btn_disabled:disabled {
  background-color: #d2d2d2; }

.Modals {
  z-index: 10000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh; }
  .Modals_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 9999; }
  .Modals .modal-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh; }
    .Modals .modal-wrapper .modal-body {
      display: flex;
      flex-direction: column;
      background: #EFF3F6;
      padding: 1.5em;
      border-radius: 10px;
      font-size: 1.1em;
      text-align: center;
      align-items: center;
      position: relative; }
      @media screen and (max-width: 480px) {
        .Modals .modal-wrapper .modal-body {
          min-width: inherit;
          max-width: inherit;
          width: 100vw;
          height: 101vh;
          padding-top: 5rem;
          border-radius: 0; } }
      .Modals .modal-wrapper .modal-body .modal_close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -3.3rem;
        color: white;
        top: 0.2rem;
        font-size: 3.3rem;
        border-radius: 100%;
        width: 2.5rem;
        height: 2.5rem; }
        .Modals .modal-wrapper .modal-body .modal_close:hover {
          cursor: pointer;
          color: #66D2EA; }
        @media screen and (max-width: 480px) {
          .Modals .modal-wrapper .modal-body .modal_close {
            right: 35px;
            color: black;
            top: 55px; } }
      .Modals .modal-wrapper .modal-body .Modal__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px; }
      .Modals .modal-wrapper .modal-body .copyright {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 2em;
        color: grey; }
        .Modals .modal-wrapper .modal-body .copyright a {
          text-decoration: none;
          font-weight: bold;
          color: grey; }
      .Modals .modal-wrapper .modal-body .feedback {
        padding-top: 1em; }
      .Modals .modal-wrapper .modal-body .form {
        margin-top: 1rem; }
        .Modals .modal-wrapper .modal-body .form > div {
          text-align: left;
          margin-bottom: 2rem;
          position: relative; }
          .Modals .modal-wrapper .modal-body .form > div:before {
            content: "\41D\435\432\435\440\43D\44B\439   \444\43E\440\43C\430\442";
            position: absolute;
            bottom: 0px;
            left: 5px;
            color: #e26969;
            font-size: 1.4rem;
            z-index: 111;
            transform: translateY(11px);
            transition: .3s;
            pointer-events: none;
            opacity: 0; }
        .Modals .modal-wrapper .modal-body .form .notValid:after {
          content: "";
          position: absolute;
          left: 1px;
          top: 0px;
          width: 3px;
          height: 100%;
          background: #e26969;
          display: block;
          z-index: 11111;
          border-radius: 243px; }
        .Modals .modal-wrapper .modal-body .form .notValid:before {
          transform: translateY(13px);
          opacity: 1; }
        .Modals .modal-wrapper .modal-body .form .notValid:focus {
          outline: none !important; }
        .Modals .modal-wrapper .modal-body .form .notValid .LandingCallback__input-icon {
          display: none; }
        .Modals .modal-wrapper .modal-body .form .input-title {
          font-weight: 700;
          text-transform: uppercase;
          font-size: .8rem;
          margin: 1rem 0 0.5rem 0; }
        .Modals .modal-wrapper .modal-body .form input[type="text"] {
          padding: 10px 15px;
          border-radius: 5px;
          border: 1px solid rgba(31, 32, 65, 0.25);
          width: 240px;
          font-size: 2rem;
          position: relative;
          outline: none !important; }
      .Modals .modal-wrapper .modal-body .modal-body_title {
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
        color: #969696;
        margin-bottom: 0;
        line-height: 1.2rem; }
      .Modals .modal-wrapper .modal-body .modal-body_logo {
        width: 200px;
        margin: 0 0 2.3em 0;
        opacity: 0.7; }
      @media screen and (max-width: 480px) {
        .Modals .modal-wrapper .modal-body {
          display: flex;
          justify-content: center; } }
    .Modals .modal-wrapper .modal_joinToModel {
      max-width: 250px; }
      .Modals .modal-wrapper .modal_joinToModel h3 {
        font-weight: 500;
        color: #252525;
        font-size: 2rem; }
      .Modals .modal-wrapper .modal_joinToModel .form-element {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: .1rem;
        background: #fafafa;
        border: 1px solid #efefef;
        margin: 0 0 .3em;
        align-items: start;
        width: 90%;
        z-index: 1; }
