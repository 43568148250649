.UserQuestionsWizard {
  background: white;
  padding: 2em;
  border-radius: 1em;
  box-shadow: 0px 0px 10px rgba(55, 84, 170, 0.1);
  width: 100%;
  min-height: 224.2px;
  max-width: 440px; }
  @media screen and (max-width: 480px) {
    .UserQuestionsWizard {
      min-width: auto;
      width: 100%;
      padding: 2em 1em; } }
  .UserQuestionsWizard_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px; }
    .UserQuestionsWizard_footer_next-buttons {
      min-width: 26em;
      justify-content: space-between; }
    .UserQuestionsWizard_footer > div {
      display: flex;
      align-items: center; }
      .UserQuestionsWizard_footer > div > div {
        margin: 0 20px; }
  .UserQuestionsWizard .Wizard_child h3 {
    font-size: 2em;
    margin-bottom: 10px; }
    @media screen and (max-width: 480px) {
      .UserQuestionsWizard .Wizard_child h3 {
        font-size: 2.1rem; } }
  .UserQuestionsWizard .Wizard_child textarea, .UserQuestionsWizard .Wizard_child input {
    width: -webkit-fill-available;
    width: 100%;
    border-radius: 0.5em;
    font-size: 2rem;
    padding: 10px 15px;
    border: 1px solid #b3b3b3; }
  .UserQuestionsWizard .Wizard_child textarea {
    overflow: auto;
    min-height: 7em;
    resize: none; }
  .UserQuestionsWizard .Wizard_child .btn {
    background: #ff981f;
    color: #fff;
    padding: 10px 20px; }
    .UserQuestionsWizard .Wizard_child .btn:hover {
      background: #fb8c0a; }
  .UserQuestionsWizard .QuestionsWizard-SentMessage {
    font-size: 2rem; }

.form_back form > * {
  margin-bottom: 10px; }

.form_back {
  margin-top: 15px; }

p.form-exit-txt {
  font-size: 16px; }
