.case-box {
  max-width: 42em;
  margin: 0 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(55, 84, 170, 0.1); }
  @media screen and (max-width: 776px) {
    .case-box {
      max-width: 310px; } }
  .case-box .case-inner {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px; }
    @media screen and (max-width: 776px) {
      .case-box .case-inner {
        flex-direction: column; } }
    .case-box .case-inner .case-img {
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat; }
      .case-box .case-inner .case-img img {
        width: 100%;
        border-radius: 10px 10px 0 0;
        height: auto; }
    .case-box .case-inner .case-content {
      width: 400px;
      padding: 20px;
      position: relative; }
      @media screen and (max-width: 1120px) {
        .case-box .case-inner .case-content {
          width: 320px; } }
      @media screen and (max-width: 776px) {
        .case-box .case-inner .case-content {
          margin-left: 0;
          margin-top: 0; } }
      .case-box .case-inner .case-content .case-logo img {
        width: auto;
        max-height: 30px; }
      .case-box .case-inner .case-content h4 {
        margin-bottom: 10px;
        font-size: 2.4rem; }
        @media screen and (max-width: 776px) {
          .case-box .case-inner .case-content h4 {
            margin-bottom: 10px;
            font-size: 2rem; } }
      .case-box .case-inner .case-content .case-description .fa-check {
        color: #5a5a5a;
        font-size: 13px;
        margin-right: 5px; }
      .case-box .case-inner .case-content .case-description p {
        font-size: 2rem;
        margin-bottom: 10px; }
        @media screen and (max-width: 776px) {
          .case-box .case-inner .case-content .case-description p {
            font-size: 1.75rem; } }
      .case-box .case-inner .case-content .case-logo {
        margin-bottom: 20px;
        position: absolute;
        left: 10px;
        top: -30px;
        background: white;
        padding: 6px 10px;
        border-radius: 9px;
        box-shadow: 0px -10px 10px rgba(55, 84, 170, 0.1); }
        @media screen and (max-width: 776px) {
          .case-box .case-inner .case-content .case-logo {
            margin-bottom: 10px; } }
