* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: 'HelveticaNeueCyr';
  font-size: 8px; }

body {
  margin: 0;
  font-size: 1.2rem;
  background: #EFF3F6;
  overflow-x: hidden; }

main {
  display: block; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

.Section_title {
  text-align: center;
  margin-bottom: 6rem; }
  @media screen and (max-width: 776px) {
    .Section_title {
      margin-bottom: 5rem; } }
  .Section_title h3 {
    font-size: 7rem; }
    @media screen and (max-width: 776px) {
      .Section_title h3 {
        font-size: 4rem; } }
  .Section_title p {
    font-size: 2.5rem;
    margin-top: 1rem; }

.btn {
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 32px;
  border-radius: 10px;
  transition: .3s;
  outline: 0 !important;
  cursor: pointer;
  font-weight: 400;
  border: 0; }
  .btn:hover {
    box-shadow: 0 0 transparent;
    transform: scale(0.98); }
  .btn:disabled:hover {
    cursor: not-allowed; }
  .btn_success {
    background: #2da562;
    color: #fff; }
    .btn_success:hover {
      background: #1a5634; }
  .btn_red {
    color: #fff;
    background: #e01e5d; }
    .btn_red:hover {
      background: #cc275c; }
  .btn i {
    margin-right: 0.35em; }
  .btn .btn_icon-bg {
    width: 20px;
    height: 20px;
    background: #2196f3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border-radius: 50%;
    padding-left: 1px;
    margin-right: 10px;
    color: #fff; }

.btn_01 {
  background: #ff981f;
  font-weight: 500;
  align-items: baseline; }
  .btn_01:hover {
    background: #fb8c0a; }

.btn_white {
  background: white;
  color: #2196f3; }

.txt_up {
  text-transform: uppercase; }

.mrg-t-10 {
  margin-top: 1.25rem; }

.mrg-t-20 {
  margin-top: 2.5rem; }

.mrg-t-30 {
  margin-top: 3.75rem; }

.mrg-t-40 {
  margin-top: 4rem; }

hr {
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: .35em .75em .625em; }

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"], [type="radio"] {
  padding: 0; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

*:after, *:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }
