.callback-block {
  display: flex;
  margin-left: 2em;
  background: #ffffff;
  color: #1e87da;
  border-radius: 0.5em;
  padding: 0.5em;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0); }
  .callback-block:hover {
    cursor: pointer;
    transition: 0.3s;
    transform: translateY(0.1em);
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2); }
  .callback-block .callback {
    font-size: 2rem !important;
    font-weight: normal !important; }

.HeaderPage {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  height: 80px;
  transition: .3s; }
  @media screen and (max-width: 420px) {
    .HeaderPage {
      height: 60px; } }
  .HeaderPage.header_sticky {
    background: #2196F3;
    height: 60px; }
    .HeaderPage.header_sticky .Navigation {
      top: 60px !important;
      transition: .3s !important;
      border-color: #1e87da !important; }
  .HeaderPage_content {
    display: flex;
    width: 100%;
    max-width: 1400px;
    padding: 0.5rem 2rem;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    color: #fff;
    font-size: 2.25rem; }
    @media screen and (max-width: 1280px) {
      .HeaderPage_content {
        padding: 10px 25px; } }
    @media screen and (max-width: 420px) {
      .HeaderPage_content {
        padding: 10px 15px; } }
    @media screen and (max-width: 375px) {
      .HeaderPage_content {
        padding: 10px 10px; } }
    .HeaderPage_content a {
      color: white;
      text-decoration: none;
      font-size: 2.25rem;
      font-weight: 300;
      margin-top: 1px; }
    .HeaderPage_content .logo {
      width: 230px;
      height: 47px;
      background-size: 100% !important;
      background: url("/logo-full.png") no-repeat center; }
      @media screen and (max-width: 480px) {
        .HeaderPage_content .logo {
          width: 65px;
          height: 51px;
          background-size: 100% !important;
          background: url("/logo-short.png") no-repeat center; } }
    .HeaderPage_content .menu-block {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .HeaderPage_content .menu-block .Navigation {
        transition: .3s; }
        @media screen and (max-width: 1120px) {
          .HeaderPage_content .menu-block .Navigation {
            position: absolute;
            width: 100%;
            left: 0;
            top: 80px;
            background: #2196f3;
            box-shadow: 1px 10px 13px 0px rgba(15, 118, 199, 0.2);
            border-top: 0.2em solid transparent; } }
        @media screen and (max-width: 420px) {
          .HeaderPage_content .menu-block .Navigation {
            top: 60px; } }
        .HeaderPage_content .menu-block .Navigation ul {
          margin-right: 30px; }
          @media screen and (max-width: 1120px) {
            .HeaderPage_content .menu-block .Navigation ul {
              padding: 10px 0; } }
          .HeaderPage_content .menu-block .Navigation ul li {
            display: inline;
            padding: 0 15px;
            font-size: 1rem;
            position: relative; }
            @media screen and (max-width: 1120px) {
              .HeaderPage_content .menu-block .Navigation ul li {
                display: block;
                font-size: 1.3rem;
                padding: 10px 25px; } }
            .HeaderPage_content .menu-block .Navigation ul li a {
              position: relative;
              text-decoration: none;
              cursor: pointer; }
              @media screen and (max-width: 1120px) {
                .HeaderPage_content .menu-block .Navigation ul li a {
                  display: block;
                  padding: 1em;
                  margin: 0;
                  margin-top: 0.5em;
                  font-weight: 500;
                  border: 0.2em solid transparent;
                  transition: 0.3s; }
                  .HeaderPage_content .menu-block .Navigation ul li a:hover, .HeaderPage_content .menu-block .Navigation ul li a:focus {
                    border-color: rgba(255, 255, 255, 0.4);
                    transition: 0.3s; } }
              .HeaderPage_content .menu-block .Navigation ul li a:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -3px;
                height: 1px;
                width: 0;
                transition: 0.2s;
                transition-timing-function: cubic-bezier(0.92, 0.49, 0.09, 0.43);
                background: white; }
                @media screen and (max-width: 1120px) {
                  .HeaderPage_content .menu-block .Navigation ul li a:after {
                    content: none;
                    height: 0;
                    width: 0; } }
              .HeaderPage_content .menu-block .Navigation ul li a:hover:after {
                width: 100%; }
      .HeaderPage_content .menu-block .phone-block {
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        text-align: right; }
        @media screen and (max-width: 375px) {
          .HeaderPage_content .menu-block .phone-block {
            margin: auto; } }
        @media screen and (min-width: 1120px) {
          .HeaderPage_content .menu-block .phone-block {
            margin-right: 0; } }
        .HeaderPage_content .menu-block .phone-block .phone-number {
          font-size: 2.75rem;
          text-decoration: none; }
          @media screen and (max-width: 320px) {
            .HeaderPage_content .menu-block .phone-block .phone-number {
              font-size: 2.3rem; } }
          .HeaderPage_content .menu-block .phone-block .phone-number i {
            font-size: 2rem; }
        .HeaderPage_content .menu-block .phone-block .callback {
          font-size: 2rem;
          text-decoration: none; }
          @media screen and (max-width: 1120px) {
            .HeaderPage_content .menu-block .phone-block .callback {
              margin-top: 2px; } }
      .HeaderPage_content .menu-block .burger-menu {
        position: relative;
        width: 40px;
        height: 50px;
        cursor: pointer; }
        @media screen and (min-width: 1120px) {
          .HeaderPage_content .menu-block .burger-menu {
            display: none; } }
        .HeaderPage_content .menu-block .burger-menu .burger {
          position: absolute;
          background: #fff;
          width: 30px;
          height: 5px;
          top: 50%;
          left: 0;
          opacity: 1;
          margin-top: -3px;
          border-radius: 50px; }
          .HeaderPage_content .menu-block .burger-menu .burger:after, .HeaderPage_content .menu-block .burger-menu .burger:before {
            position: absolute;
            background: #fff;
            width: 40px;
            height: 5px;
            content: "";
            border-radius: 50px;
            display: block;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out; }
          .HeaderPage_content .menu-block .burger-menu .burger:before {
            top: 13px; }
          .HeaderPage_content .menu-block .burger-menu .burger:after {
            bottom: 13px; }
        .HeaderPage_content .menu-block .burger-menu.menu-on .burger::after {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          bottom: 0; }
        .HeaderPage_content .menu-block .burger-menu.menu-on .burger::before {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          top: 0; }
        .HeaderPage_content .menu-block .burger-menu.menu-on .burger {
          background: rgba(111, 111, 111, 0); }
