.ServiceShort {
  margin-top: 210px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #2175f3; }
  .ServiceShort:before {
    content: "";
    position: absolute;
    left: 0;
    top: -129px;
    background: url(/svg/Service_bg-1-top.svg) no-repeat;
    background-size: cover;
    width: 100vw;
    height: 130px;
    z-index: -1; }
    @media screen and (max-width: 776px) {
      .ServiceShort:before {
        top: -79px;
        height: 80px; } }
  @media screen and (max-width: 776px) {
    .ServiceShort .Section_title {
      max-width: 430px; } }
  .ServiceShort .Section_title h3, .ServiceShort .Section_title p {
    color: white; }
  .ServiceShort .ServiceShort__layout {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin: auto;
    width: 100%; }
    .ServiceShort .ServiceShort__layout .ServiceRow {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      padding: 150px 20px 0; }
      @media screen and (max-width: 776px) {
        .ServiceShort .ServiceShort__layout .ServiceRow {
          max-width: 100% !important;
          padding: 100px 10px 20px; } }
      .ServiceShort .ServiceShort__layout .ServiceRow h4 {
        font-size: 3.125rem;
        color: white; }
        @media screen and (max-width: 1120px) {
          .ServiceShort .ServiceShort__layout .ServiceRow h4 {
            font-size: 2.6rem !important; } }
      .ServiceShort .ServiceShort__layout .ServiceRow.Service-1 {
        background: #2175F3;
        padding: 0 20px; }
        @media screen and (max-width: 1120px) {
          .ServiceShort .ServiceShort__layout .ServiceRow.Service-1 {
            padding-top: 0px; } }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-1:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -98px;
          background: url(/svg/Service_bg-1-bottom.svg) no-repeat;
          background-size: cover;
          width: 100vw;
          height: 100px;
          z-index: 1; }
          @media screen and (max-width: 375px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-1:after {
              bottom: -58px;
              height: 60px; } }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-1 img {
          max-width: 570px; }
          @media screen and (max-width: 1120px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-1 img {
              width: 400px; } }
          @media screen and (max-width: 480px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-1 img {
              width: 100%; } }
      .ServiceShort .ServiceShort__layout .ServiceRow.Service-2 {
        background: #2196F3; }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-2:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -98px;
          background: url(/svg/Service_bg-2-bottom.svg) no-repeat;
          background-size: cover;
          width: 100vw;
          height: 100px;
          z-index: 1; }
          @media screen and (max-width: 375px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-2:after {
              bottom: -58px;
              height: 60px; } }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-2 img {
          max-width: 530px; }
          @media screen and (max-width: 1120px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-2 img {
              width: 390px;
              margin-top: 40px; } }
          @media screen and (max-width: 480px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-2 img {
              width: 100%; } }
      .ServiceShort .ServiceShort__layout .ServiceRow.Service-3 {
        background: #66D2EA; }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-3:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -98px;
          background: url(/svg/Service_bg-3-bottom.svg) no-repeat;
          background-size: cover;
          width: 100vw;
          height: 100px;
          z-index: 1; }
          @media screen and (max-width: 375px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-3:after {
              bottom: -58px;
              height: 60px; } }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-3 img {
          max-width: 530px; }
          @media screen and (max-width: 1120px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-3 img {
              width: 400px;
              margin-top: 20px; } }
          @media screen and (max-width: 480px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-3 img {
              width: 100%; } }
      .ServiceShort .ServiceShort__layout .ServiceRow.Service-4 {
        background: #6966EA; }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-4:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -98px;
          background: url(/svg/Service_bg-4-bottom.svg) no-repeat;
          background-size: cover;
          width: 100vw;
          height: 100px;
          z-index: 1; }
          @media screen and (max-width: 400px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-4:after {
              height: 70px;
              bottom: -68px; } }
        .ServiceShort .ServiceShort__layout .ServiceRow.Service-4 img {
          max-width: 550px; }
          @media screen and (max-width: 1120px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-4 img {
              width: 400px;
              margin-top: 40px; } }
          @media screen and (max-width: 480px) {
            .ServiceShort .ServiceShort__layout .ServiceRow.Service-4 img {
              width: 100%; } }
      .ServiceShort .ServiceShort__layout .ServiceRow .ServiceRow_inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1120px; }
        @media screen and (max-width: 1120px) {
          .ServiceShort .ServiceShort__layout .ServiceRow .ServiceRow_inner {
            justify-content: space-around; } }
        @media screen and (max-width: 776px) {
          .ServiceShort .ServiceShort__layout .ServiceRow .ServiceRow_inner {
            flex-direction: column-reverse; } }
      @media screen and (max-width: 776px) {
        .ServiceShort .ServiceShort__layout .ServiceRow .ServiceRow_reverse {
          flex-direction: column; } }
      @media screen and (max-width: 480px) {
        .ServiceShort .ServiceShort__layout .ServiceRow .ServiceRow_reverse img {
          margin: 0 0 0 2.5%; } }
      .ServiceShort .ServiceShort__layout .ServiceRow .ServiceBox_text {
        max-width: 480px; }
        @media screen and (max-width: 1120px) {
          .ServiceShort .ServiceShort__layout .ServiceRow .ServiceBox_text {
            max-width: 330px;
            margin-top: 20px; } }
        @media screen and (max-width: 400px) {
          .ServiceShort .ServiceShort__layout .ServiceRow .ServiceBox_text {
            width: 310px; } }
      .ServiceShort .ServiceShort__layout .ServiceRow ul {
        list-style: none;
        font-size: 2.5rem;
        margin-top: 20px; }
        .ServiceShort .ServiceShort__layout .ServiceRow ul li {
          list-style: none;
          font-size: 2.5rem;
          margin-top: 10px;
          color: white; }
          @media screen and (max-width: 1120px) {
            .ServiceShort .ServiceShort__layout .ServiceRow ul li {
              font-size: 2rem; } }
          .ServiceShort .ServiceShort__layout .ServiceRow ul li i {
            font-size: 1.6rem; }
