.Performers {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px auto 0; }
  @media screen and (max-width: 480px) {
    .Performers h3 {
      max-width: 370px; } }
  .Performers .PerformersButton {
    margin-top: 20px; }
  .Performers .btn {
    font-size: 2.5rem;
    padding: 10px 25px; }
  .Performers .PerformersShort {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .Performers .PerformersShort_item {
      width: 30%;
      transition: 0.3s;
      margin: 0 1% 1%;
      min-width: 280px; }
      @media screen and (max-width: 776px) {
        .Performers .PerformersShort_item {
          margin: 0 10px 10px; } }
      @media screen and (max-width: 480px) {
        .Performers .PerformersShort_item {
          min-width: 370px;
          margin: 0 10px 10px; } }
      @media screen and (max-width: 400px) {
        .Performers .PerformersShort_item {
          min-width: 300px; } }
    .Performers .PerformersShort .profile-right-icon {
      display: flex;
      align-items: center;
      justify-content: center; }
      .Performers .PerformersShort .profile-right-icon .star-icon {
        color: #e6d82f;
        margin-right: 0.3em;
        font-size: 0.9em; }
    .Performers .PerformersShort .profile-box {
      padding-bottom: 100%;
      width: 100%;
      height: 100%;
      background-size: cover;
      position: relative;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: -11px 14px 40px rgba(0, 0, 0, 0.2); }
      @media screen and (max-width: 776px) {
        .Performers .PerformersShort .profile-box {
          box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.1); } }
      .Performers .PerformersShort .profile-box:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(255, 0, 0, 0)); }
    .Performers .PerformersShort .profile-name {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      color: white;
      padding: 0 15px 10px;
      z-index: 2;
      transition: .3s; }
      .Performers .PerformersShort .profile-name .profile-title {
        color: currentColor;
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 500; }
      .Performers .PerformersShort .profile-name .profile-description {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 2.5rem; }
        .Performers .PerformersShort .profile-name .profile-description .user-age {
          font-weight: 200; }
        .Performers .PerformersShort .profile-name .profile-description .svg-icon {
          width: 15px;
          margin-right: 5px; }
        .Performers .PerformersShort .profile-name .profile-description .profile-description .svg-icon {
          width: 13px;
          margin-right: 5px; }
