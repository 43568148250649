.PriceCalculator {
  max-width: 1280px;
  margin: auto;
  margin-top: 120px;
  padding: 0 20px; }
  @media screen and (max-width: 1120px) {
    .PriceCalculator {
      padding: 0; } }
  @media screen and (max-width: 1120px) {
    .PriceCalculator {
      flex-direction: column;
      align-items: center;
      margin: 100px auto 0;
      max-width: 600px; } }
  @media screen and (max-width: 776px) {
    .PriceCalculator {
      margin: 80px auto 0; } }
  @media screen and (max-width: 600px) {
    .PriceCalculator {
      max-width: 400px; } }
  .PriceCalculator .Section_title h3 {
    font-size: 6rem; }
    @media screen and (max-width: 991px) {
      .PriceCalculator .Section_title h3 {
        font-size: 5rem; } }
    @media screen and (max-width: 776px) {
      .PriceCalculator .Section_title h3 {
        font-size: 4rem; } }
  .PriceCalculator .PriceCalculator_section {
    display: flex; }
    @media screen and (max-width: 1120px) {
      .PriceCalculator .PriceCalculator_section {
        flex-direction: column; } }
  .PriceCalculator_fixed {
    position: sticky;
    top: 80px;
    padding: 30px;
    background: linear-gradient(54.39deg, #2196F3 33.73%, #66D2EA 99.57%);
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px; }
    @media screen and (max-width: 1120px) {
      .PriceCalculator_fixed {
        padding: 10px 10px;
        width: 600px;
        margin-top: 20px; } }
    @media screen and (max-width: 600px) {
      .PriceCalculator_fixed {
        width: 100%; } }
  .PriceCalculator .PriceCalculator_result {
    width: 45%;
    color: white;
    padding-left: 20px; }
    @media screen and (max-width: 1120px) {
      .PriceCalculator .PriceCalculator_result {
        width: 100%;
        padding-left: 0; } }
    @media screen and (max-width: 776px) {
      .PriceCalculator .PriceCalculator_result {
        padding: 10px; } }
    @media screen and (max-width: 480px) {
      .PriceCalculator .PriceCalculator_result {
        padding: 0 10px;
        min-width: auto; } }
    .PriceCalculator .PriceCalculator_result .PriceTitle {
      padding: 1em 1em 1em 0em;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      line-height: 1.7em; }
      .PriceCalculator .PriceCalculator_result .PriceTitle h4 {
        font-size: 4rem;
        margin: 0 0 5px 0; }
        @media screen and (max-width: 1120px) {
          .PriceCalculator .PriceCalculator_result .PriceTitle h4 {
            font-size: 3rem; } }
      .PriceCalculator .PriceCalculator_result .PriceTitle .PriceNumber {
        font-size: 3.15rem; }
  .PriceCalculator .PriceCalculator_calculate {
    width: 55%;
    text-transform: uppercase;
    padding-right: 20px; }
    @media screen and (max-width: 1120px) {
      .PriceCalculator .PriceCalculator_calculate {
        width: auto;
        max-width: 600px;
        margin: auto;
        padding-right: 0; } }
    @media screen and (max-width: 600px) {
      .PriceCalculator .PriceCalculator_calculate {
        padding: 10px 10px;
        width: 100%; } }
    .PriceCalculator .PriceCalculator_calculate .CalculatorItem .ButtonGroup {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px; }
    .PriceCalculator .PriceCalculator_calculate .CalculatorItem_result {
      font-size: 1.25em;
      padding-left: 0.5em;
      font-weight: 300; }
    .PriceCalculator .PriceCalculator_calculate .CalculatorItem h6 {
      font-size: 1.5rem;
      font-weight: 900; }
    .PriceCalculator .PriceCalculator_calculate .CalculatorItem.Gender:first-child h6 {
      margin-top: 0; }
    .PriceCalculator .PriceCalculator_calculate .ButtonGroup .btn {
      background: #F7F7F7;
      box-shadow: 0px 0px 10px rgba(55, 84, 170, 0.1);
      border-radius: 5px;
      border: none;
      margin-right: 10px;
      margin-bottom: 10px; }
      .PriceCalculator .PriceCalculator_calculate .ButtonGroup .btn__selected {
        color: #fff;
        background: #2196f3;
        box-shadow: 0 0 transparent !important; }
    .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem {
      padding: 5px 0;
      position: relative;
      margin-top: 30px; }
      .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 20px; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .Slider_one-value, .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .Slider_range {
          width: 540px; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider input[type="range"] {
          width: 100%;
          max-width: 350px; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .rc-slider-rail, .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .rc-slider-track {
          height: 8px; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .rc-slider-track {
          height: 8px;
          background: #2196f3; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .rc-slider-handle {
          margin-top: -7px;
          background: #2196f3; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .SliderText_from {
          width: 30px;
          font-size: 1.5rem;
          font-weight: 500; }
        .PriceCalculator .PriceCalculator_calculate .SliderBox .CalculatorItem .Slider .SliderText_to {
          font-weight: 500;
          font-size: 1.5rem;
          margin-left: 10px; }
    .PriceCalculator .PriceCalculator_calculate .TextArea textarea {
      overflow: auto;
      resize: vertical;
      width: 100%;
      max-width: 570px;
      padding: 1em;
      border-radius: 5px;
      border: 0.15em solid rgba(0, 168, 255, 0.4);
      height: 140px;
      margin-top: 20px;
      font-size: 1.75rem;
      outline: none !important; }
      @media screen and (max-width: 1120px) {
        .PriceCalculator .PriceCalculator_calculate .TextArea textarea {
          max-width: 600px;
          height: 130px; } }
    .PriceCalculator .PriceCalculator_calculate ::-webkit-input-placeholder {
      color: #bbbbbb; }
    .PriceCalculator .PriceCalculator_calculate ::-moz-placeholder {
      color: #bbbbbb; }
    .PriceCalculator .PriceCalculator_calculate :-moz-placeholder {
      color: #bbbbbb; }
    .PriceCalculator .PriceCalculator_calculate :-ms-input-placeholder {
      color: #bbbbbb; }
  .PriceCalculator .PriceFile {
    margin-top: 20px; }
    .PriceCalculator .PriceFile .Title {
      text-transform: uppercase;
      position: relative; }
      .PriceCalculator .PriceFile .Title .Tooltip_icon {
        background: white;
        color: black;
        font-size: 12px;
        padding: 1px 5px;
        border-radius: 11px;
        width: 5px;
        height: 12px;
        position: absolute;
        top: 0px;
        right: -20px;
        cursor: pointer; }
        .PriceCalculator .PriceFile .Title .Tooltip_icon:hover .Tooltip {
          opacity: 1 !important; }
        .PriceCalculator .PriceFile .Title .Tooltip_icon .Tooltip {
          color: #131313;
          position: absolute;
          top: 0;
          right: -160px;
          width: 130px;
          background: white;
          font-size: 12px;
          padding: 8px 10px;
          border-radius: 7px;
          opacity: 0;
          transition: .3s;
          pointer-events: none;
          text-transform: none; }
          .PriceCalculator .PriceFile .Title .Tooltip_icon .Tooltip:after {
            content: "";
            position: absolute;
            top: 0;
            left: -9px;
            width: 10px;
            height: 10px;
            border: 10px solid;
            border-color: white transparent transparent transparent; }
    .PriceCalculator .PriceFile input[type="file"] {
      display: none; }
    .PriceCalculator .PriceFile .button-wrapper span {
      width: auto;
      border-radius: 4px;
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
      transition: 0.3s;
      vertical-align: top;
      text-transform: uppercase; }
      .PriceCalculator .PriceFile .button-wrapper span i {
        margin-right: 5px; }
    .PriceCalculator .PriceFile .button-wrapper .file_name {
      background: transparent;
      border: 0;
      color: white;
      display: inline-block;
      vertical-align: top;
      height: 30px;
      padding: 0 8px;
      width: 150px; }
  .PriceCalculator .button-send .btn {
    padding: 10px 15px;
    border: 0;
    background: #ff981f;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
    transition: .3s; }
    .PriceCalculator .button-send .btn span {
      display: flex;
      align-items: center;
      justify-content: center; }
    .PriceCalculator .button-send .btn:hover {
      box-shadow: 0 0 transparent !important;
      transform: scale(0.98);
      background: #fb8c0a; }
    .PriceCalculator .button-send .btn .btn-icon {
      background: #2196f3;
      color: white;
      padding: 3px 6px;
      border-radius: 50%;
      margin-right: 10px;
      font-size: 0.9rem; }
  .PriceCalculator .button-send_additional {
    font-size: 1.5rem;
    color: #ffffff;
    cursor: default;
    margin-top: 20px; }
  .PriceCalculator .PriceResult_box div {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 2rem; }
